import {
  AccountStatus,
  ApprovalState,
  ApprovalStatus,
  BankAccountType,
  BankAccountV2,
  BankType,
  ContactType,
  CurrencyCode,
  Document,
  DocumentStatus,
  FundingSourceStatus,
  LinkedPaymentMethod,
  MembershipType,
  PaymentMethodCode,
  PaymentMethodLinkStatus,
  RegistryLinkStatus,
} from "@/app/types/generated/graphql";

export const demoAccountCC = {
  id: "a0883295-778f-4ac8-937a-46c8bd89678f",
  tenantId: "08cd2505-18d4-488b-a960-2195463332e8",
  firstName: "John",
  lastName: "Smith",
  banks: [
    {
      id: "123-123-1231-23-123",
      accountNumber: "0002-3123-123123-123123-123123",
      bankName: "SBI India",
      branchAddress: "Garia Road 3",
      ifsCode: "11123",
      type: BankType["Settlement"],
    },
    {
      id: "123-123-1231-23-1234",
      accountNumber: "0002-3123-123123-123123-123123",
      bankName: "SBI India",
      branchAddress: "Garia Road 3",
      ifsCode: "11123",
      type: BankType["Fund"],
    },
  ],
  email: "powerledgertest@powerledger.io",
  contactNumber: "97788871234",
  status: AccountStatus.Active,
  identityProviderRef: "123",
  members: [
    {
      firstName: "Powerledger",
      lastName: "SUN",
      id: "testst-123",
      membershipType: MembershipType["Proprietor"],
      registries: [
        {
          id: "123123",
          registryName: "Gold",
          accountNumber: "0808-10203-12304-1111-2233",
          accountName: "Powerledger Sun",
        },
        {
          id: "123124",
          registryName: "Verra",
          accountNumber: "0810-10203-12304-1111-2112",
          accountName: "Powerledger TraceCC",
        },
      ],
      invoiceEmail: "powerledgertest@powerledger.io",
      invoiceContactNumber: "97788123456",
      membershipId: "1101-2234-0000-V4-123",
      clientCode: "00001",
      name: "Powerledger Member",
      registeredAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd89678s",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      businessAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd89671s",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      invoiceAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd19678s",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      gstin: "110230410",
      gstRegistrationAddress: "Kolkata, Garia Main Road 3",
      panNumber: "1123123123",
      tanNumber: "123123123",
    },
    {
      firstName: "Powerledger",
      lastName: "TraceCC",
      id: "testst-12345",
      membershipType: MembershipType["Client"],
      registries: [
        {
          id: "1231124",
          registryName: "Gold",
          accountNumber: "0818-10203-12304-1111-2233",
          accountName: "Powerledger Sun 2",
        },
        {
          id: "123124123123",
          registryName: "Verra",
          accountNumber: "0823-10203-12304-1111-2112",
          accountName: "Powerledger TraceCC 2",
        },
      ],
      invoiceEmail: "powerledgertest2@powerledger.io",
      invoiceContactNumber: "97788123416",
      membershipId: "1101-2234-0000-123-123",
      clientCode: "00002",
      name: "Powerledger Member2",
      registeredAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd896784",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      businessAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd896715",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      invoiceAddress: {
        id: "a0883295-778f-4ac8-937a-46c8bd196786",
        countryCode: "IN",
        state: "WB",
        city: "Kolkata",
        line1: "Garia Main Road 3",
        postalCode: "700031",
        line2: "",
      },
      gstin: "110230410",
      gstRegistrationAddress: "Kolkata, Garia Main Road 3",
      panNumber: "1123123123",
      tanNumber: "123123123",
    },
  ],
};

export const fewAccountDetails = {
  id: "effe3af5-c1c9-4518-a2bb-03673448e988",
  termsAcceptedDate: "2023-10-11T13:25:11.567Z",
  rulesAcceptedDate: "2023-10-11T13:25:11.567Z",
  privacyPolicyAcceptedDate: "2023-10-11T13:25:11.567Z",
  status: "ACTIVE",
  tenantId: "e01fb908-d304-4872-8f5c-64eac7fb10d6",
  firstName: "John",
  lastName: "Smith",
  email: "powerledgertest@powerledger.io",
};

export const demoAccount = {
  identityProviderRef: "a0883295-778f-4ac8-937a-46c8bd89678f",
  id: "df30cd30-a590-446f-a93b-af9d1520c37f",
  displayName: null,
  tenantId: "08cd2505-18d4-488b-a960-2195463332e8",
  firstName: "John",
  lastName: "Smith",
  email: "kefimaco@acrossgracealley.com",
  contactNumber: null,
  status: AccountStatus.Active,
  linkedRegistries: [
    {
      id: "e2e2ec2d-5158-4c7b-9996-b70e36c7dec2",
      registryOrgLinkId: "MM123",
      status: RegistryLinkStatus.Registered,
    },
  ],
  enrolledFactors: [],
  privacyPolicyAcceptedDate: "2022-08-30T06:31:05.117208Z",
  termsAcceptedDate: "2022-08-30T06:31:05.117208Z",
  rulesAcceptedDate: "2022-08-30T06:31:05.117208Z",
  acknowledgementDate: "2022-08-30T06:31:05.117208Z",
  startDateTime: "2022-08-24T08:06:08.946584Z",
  endDateTime: null,
  company: {
    id: "31faf17e-1bd5-481b-b948-80619e7b4141",
    businessName: "AAQ",
    businessType: "partnership",
    industryClassification: {
      id: "9ed35a1f-7d6f-11e3-ba72-5404a6144203",
      name: "Art and craft supplies",
      businessClassificationName: "Arts, crafts, and collectibles",
      __typename: "IndustryClassification",
    },
    employerIdentificationNumber: "12-3123123",
    physicalAddress: {
      id: "2123650e-924e-4d91-985b-b12535ce4065",
      line1: "123123",
      line2: "12313",
      city: "12313",
      state: "AZ",
      postalCode: "12313",
      countryCode: "US",
      __typename: "Address",
    },
    contacts: [
      {
        id: "e00d0875-c67a-4d74-995b-91c71fdf36e6",
        firstName: "Lei",
        lastName: "Yang",
        jobTitle: "CTO",
        dateOfBirth: "1961-09-07",
        identityNumber: "1313",
        physicalAddress: {
          id: "1f3e3557-6a55-4ef2-ace0-27c9694dbb63",
          line1: "24A Dundee Ave",
          line2: null,
          city: "Holden Hill",
          state: "AS",
          postalCode: "25088",
          countryCode: "US",
          __typename: "Address",
        },
        approvalState: {
          id: "f46d1f48-5826-4eaf-bcc0-3e7f4b8d6b3f",
          status: ApprovalStatus.Approved,
          errorMessage: "",
          errorCode: "",
          __typename: "ApprovalState",
        },
        passportNumber: null,
        passportCountryCode: null,
        type: ContactType.BeneficialOwner,
        documents: [
          {
            id: "asdf-asdf-asdf-asdf-asdf",
            fileName: "test-document-upload-success.png",
            type: "ID_CARD",
            mediaType: "image/png",
            size: 3453,
            status: DocumentStatus.Uploaded,
            errorMessage: null,
            approvalState: {
              id: "2ffe4640-533d-4e82-a9ee-c1c8810fb899",
              status: ApprovalStatus.Approved,
              errorMessage: null,
              errorCode: null,
              __typename: "ApprovalState",
            },
            __typename: "Document",
          } as Document,
        ],
        __typename: "CompanyContact",
      },
      {
        id: "2396c5b1-f69c-4433-a762-a5d7e27ab2b2",
        firstName: "Ethan",
        lastName: "Sun",
        jobTitle: "CCC2",
        dateOfBirth: "1971-08-16",
        identityNumber: "3123",
        physicalAddress: {
          id: "8ba234f8-7e3f-42d7-a392-b798a2fa067e",
          line1: "8/45 Fourth Ave",
          line2: null,
          city: "Klemzig",
          state: "AS",
          postalCode: "50874",
          countryCode: "US",
          __typename: "Address",
        },
        passportNumber: null,
        passportCountryCode: null,
        type: ContactType.Controller,
        __typename: "CompanyContact",
        approvalState: {
          id: "f46d1f48-5826-4eaf-bcc0-asdf123sdf",
          status: ApprovalStatus.Approved,
          errorMessage: "",
          errorCode: "",
          __typename: "ApprovalState",
        },
        documents: [
          {
            id: "asdf-asdf-asdf-asdf-asdf",
            fileName: "test-document-upload-success.png",
            type: "ID_CARD",
            mediaType: "image/png",
            size: 3453,
            status: DocumentStatus.Uploaded,
            errorMessage: null,
            approvalState: {
              id: "2ffe4640-533d-4e82-a9ee-c1c8810fb899",
              status: ApprovalStatus.NotRequested,
              errorMessage: null,
              errorCode: null,
              __typename: "ApprovalState",
            },
            __typename: "Document",
          } as Document,
        ],
      },
    ],
    documents: [
      {
        id: "asdf-asdf-asdf-asdf-asdf",
        fileName: "test-document-upload-success.png",
        type: "ID_CARD",
        mediaType: "image/png",
        size: 3453,
        status: DocumentStatus.Uploaded,
        errorMessage: null,
        approvalState: {
          id: "2ffe4640-533d-4e82-a9ee-c1c8810fb899",
          status: ApprovalStatus.Approved,
          errorMessage: null,
          errorCode: null,
          __typename: "ApprovalState",
        },
        __typename: "Document",
      } as Document,
    ],
    approvalState: {
      id: "2ffe4640-533d-4e82-a9ee-1231251221",
      status: ApprovalStatus.Approved,
      errorMessage: null,
      errorCode: null,
      __typename: "ApprovalState",
    },
    __typename: "Company",
  },
  linkedPaymentMethods: [
    {
      id: "c1064110-60bc-48be-a20b-af4a7bdd2a9d",
      initialisingToken: "test-init-token",
      paymentMethod: {
        id: "831ff9ef-907f-4bfe-bb6d-73895acc6fe1",
        code: PaymentMethodCode.DwollaPlaid,
        displayName: "Plaid",
        description:
          "Using the Dwolla Plaid Link, verify your accounts in seconds by simply inputting your banking credentials via Plaid",
        paymentProvider: {
          __typename: "PaymentProviderV2",
          id: "4dcaaa65-c49e-4543-9fd0-0c5c36029983",
          name: "DWOLLA",
          displayName: "Dwolla",
          description: "Dwolla",
          paymentMethods: [],
        },
        __typename: "PaymentMethod",
      },
      status: PaymentMethodLinkStatus.Active,
      statusMessage: "Test",
      fundingSource: {
        __typename: "BankAccountV2",
        id: "a5aeba91-fc94-482c-9c45-0b6ead7c8cd8",
        currencyCode: CurrencyCode.Usd,
        status: FundingSourceStatus.Verified,
        statusMessage: "Verified",
        errorCode: "",
        accountName: "Example",
        accountNumberMasked: "1234",
        accountType: BankAccountType.Savings,
      } as BankAccountV2,
      __typename: "LinkedPaymentMethod",
    } as LinkedPaymentMethod,
  ],
};

export const demoAccountWithUploadedDocuments = {
  ...demoAccount,
  company: {
    ...demoAccount.company,
    documents: [
      {
        id: "1321-5125412-151",
        fileName: "test-document-upload-success.png",
        type: "ID_CARD",
        mediaType: "image/png",
        size: 3453,
        status: DocumentStatus.Uploaded,
        errorMessage: null,
        approvalState: {
          id: "5c7b9c38-ef3c-4301-b3dc-7f38a4cd07c6",
          status: ApprovalStatus.Approved,
          errorMessage: null,
          errorCode: null,
          __typename: "ApprovalState",
        } as ApprovalState,
        __typename: "Document",
      } as Document,
    ],
  },
};
