import { CcOrderAttributesOptionsQuery, RecOrderAttributesOptionsQuery } from "@/app/types/generated/graphql";

export const demoAssetsCC: CcOrderAttributesOptionsQuery = {
  assets: [
    {
      attributes: [
        {
          name: "vintage",
          values: [
            {
              code: "2022",
              displayName: "2022",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2021",
              displayName: "2021",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2020",
              displayName: "2020",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2019",
              displayName: "2019",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2018",
              displayName: "2018",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2017",
              displayName: "2017",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2016",
              displayName: "2016",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2015",
              displayName: "2015",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "1d52db21-daf0-4cd6-bd96-57a9aeb5ee34",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "registry",
          values: [
            {
              code: "Verra",
              displayName: "Verra",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Gold Standard",
              displayName: "Gold Standard",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "e071eb77-77f9-4d49-905d-69961e91d981",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "projectTypes",
          values: [
            {
              code: "Solar",
              displayName: "Solar",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Wind",
              displayName: "Windd",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Hydro",
              displayName: "Hydro",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Biogas",
              displayName: "Biogas",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Biochar",
              displayName: "Biochar",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Clean Water",
              displayName: "Clean Water",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Lighting efficiency",
              displayName: "Lighting efficiency",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Clean cookstove distribution",
              displayName: "Clean cookstove distribution",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Carbon Capture and storage",
              displayName: "Carbon Capture and storage",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Fuel Switching",
              displayName: "Fuel Switching",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Recycling",
              displayName: "Recycling",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Waste heat recovery",
              displayName: "Waste heat recovery",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Waste to energy",
              displayName: "Waste to energy",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Grass land Management",
              displayName: "Grass land Management",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Livestock management",
              displayName: "Livestock management",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Sustainable Agriculture",
              displayName: "Sustainable Agriculture",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Public Transportation",
              displayName: "Public Transportation",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Electric Vehicle",
              displayName: "Electric Vehicle",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "REDD +",
              displayName: "REDD +",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Blue Carbon",
              displayName: "Blue Carbon",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Afforestation and Reforestation",
              displayName: "Afforestation and Reforestation",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Agroforestry",
              displayName: "Agroforestry",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Improved Forest Management",
              displayName: "Improved Forest Management",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Urban forestry",
              displayName: "Urban forestry",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "80585a4c-ec74-4ba4-aa69-efd931a1200f",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "sectoralScopes",
          values: [
            {
              code: "Energy industries - renewable sources",
              displayName: "Energy industries - renewable sources",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Energy Industries - non renewable sources",
              displayName: "Energy Industries - non renewable sources",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Energy distribution",
              displayName: "Energy distribution",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Energy demand",
              displayName: "Energy demand",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Manufacturing industries",
              displayName: "Manufacturing industries",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Chemical industries",
              displayName: "Chemical industries",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Construction",
              displayName: "Construction",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Transport",
              displayName: "Transport",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Mining and Mineral Production",
              displayName: "Mining and Mineral Production",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Metal production",
              displayName: "Metal production",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Fugitive Emission from fuel( solid, liquid, gas)",
              displayName: "Fugitive Emission from fuel( solid, liquid, gas)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Fugitive emission from Production and consumption from holocarbon and SF6",
              displayName: "Fugitive emission from Production and consumption from holocarbon and SF6",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Solvent",
              displayName: "Solvent",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Waste handling and disposal",
              displayName: "Waste handling and disposal",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Afforestation and Reforestation",
              displayName: "Afforestation and Reforestation",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Agriculture",
              displayName: "Agriculture",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "3132770d-ac1a-407d-a407-479410db3e6a",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "additionalCoBenefits",
          values: [
            {
              code: "CORSIA Labelled",
              displayName: "CORSIA Labelled",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "GCC",
              displayName: "GCC",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG silver",
              displayName: "SDG silver",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG gold",
              displayName: "SDG gold",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG platinum",
              displayName: "SDG platinum",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "Gold Standard SDG",
              displayName: "Gold Standard SDG",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "89225633-a19f-4cec-baba-9cc33cf0e689",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "sdgGoals",
          values: [
            {
              code: "SDG 1- No Poverty",
              displayName: "SDG 1- No Poverty",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 2 - Zero Hunger",
              displayName: "SDG 2 - Zero Hunger",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 3 - Good Health and Well-being",
              displayName: "SDG 3 - Good Health and Well-being",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 4 - Quality Education",
              displayName: "SDG 4 - Quality Education",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 5 - Gender Equality",
              displayName: "SDG 5 - Gender Equality",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 6 - Clean Water and Sanitation",
              displayName: "SDG 6 - Clean Water and Sanitation",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 7 - Affordable and Clean Energy",
              displayName: "SDG 7 - Affordable and Clean Energy",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 8 - Decent Work and Economic Growth",
              displayName: "SDG 8 - Decent Work and Economic Growth",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 9 - Industry, Innovation and Infrastructure",
              displayName: "SDG 9 - Industry, Innovation and Infrastructure",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 10 - Reduced Inequality",
              displayName: "SDG 10 - Reduced Inequality",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 11 - Sustainable Cities and Communities",
              displayName: "SDG 11 - Sustainable Cities and Communities",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 12 - Responsible Consumption and Production",
              displayName: "SDG 12 - Responsible Consumption and Production",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 13 - Climate Action",
              displayName: "SDG 13 - Climate Action",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 14 - Life Below Water",
              displayName: "SDG 14 - Life Below Water",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 15 - Life on Land",
              displayName: "SDG 15 - Life on Land",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 16 - Peace and Justice Strong Institutions",
              displayName: "SDG 16 - Peace and Justice Strong Institutions",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SDG 17 - Partnerships to achieve the Goal",
              displayName: "SDG 17 - Partnerships to achieve the Goal",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "dc40bd34-94fb-4f4b-bea3-d908895fc963",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "country",
          values: [
            {
              code: "Angola",
              displayName: "Angola",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Benin",
              displayName: "Benin",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Botswana",
              displayName: "Botswana",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Burkina Faso",
              displayName: "Burkina Faso",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Burundi",
              displayName: "Burundi",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Cameroon",
              displayName: "Cameroon",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Cape Verde",
              displayName: "Cape Verde",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Central African Republic",
              displayName: "Central African Republic",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Chad",
              displayName: "Chad",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Comoros",
              displayName: "Comoros",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Congo",
              displayName: "Congo",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Congo, the Democratic Republic of the",
              displayName: "Congo, the Democratic Republic of the",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Côte d`Ivoire",
              displayName: "Côte d`Ivoire",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Djibouti",
              displayName: "Djibouti",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Equatorial Guinea",
              displayName: "Equatorial Guinea",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Eritrea",
              displayName: "Eritrea",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Ethiopia",
              displayName: "Ethiopia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "French Southern Territories",
              displayName: "French Southern Territories",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Gabon",
              displayName: "Gabon",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Gambia",
              displayName: "Gambia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Ghana",
              displayName: "Ghana",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Guinea",
              displayName: "Guinea",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Guinea-Bissau",
              displayName: "Guinea-Bissau",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Kenya",
              displayName: "Kenya",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Lesotho",
              displayName: "Lesotho",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Liberia",
              displayName: "Liberia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Madagascar",
              displayName: "Madagascar",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Malawi",
              displayName: "Malawi",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Mali",
              displayName: "Mali",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Mauritania",
              displayName: "Mauritania",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Mauritius",
              displayName: "Mauritius",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Mayotte",
              displayName: "Mayotte",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Mozambique",
              displayName: "Mozambique",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Namibia",
              displayName: "Namibia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Niger",
              displayName: "Niger",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Nigeria",
              displayName: "Nigeria",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Rwanda",
              displayName: "Rwanda",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Réunion",
              displayName: "Réunion",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Helena,Ascension and Tristan da Cunha",
              displayName: "Saint Helena, Ascension and Tristan da Cunha",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Sao Tome and Principe",
              displayName: "Sao Tome and Principe",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Senegal",
              displayName: "Senegal",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Seychelles",
              displayName: "Seychelles",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Sierra Leone",
              displayName: "Sierra Leone",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Somalia",
              displayName: "Somalia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "South Africa",
              displayName: "South Africa",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "South Georgia and the South Sandwich Islands",
              displayName: "South Georgia and the South Sandwich Islands",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "South Sudan",
              displayName: "South Sudan",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Sudan",
              displayName: "Sudan",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Swaziland",
              displayName: "Swaziland",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Tanzania, United Republic of",
              displayName: "Tanzania, United Republic of",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Togo",
              displayName: "Togo",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Uganda",
              displayName: "Uganda",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Western Sahara",
              displayName: "Western Sahara",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Zambia",
              displayName: "Zambia",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Zimbabwe",
              displayName: "Zimbabwe",
              key: "Africa",
              __typename: "AttributeValue",
            },
            {
              code: "Afghanistan",
              displayName: "Afghanistan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Bangladesh",
              displayName: "Bangladesh",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Bhutan",
              displayName: "Bhutan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "British Indian Ocean Territory",
              displayName: "British Indian Ocean Territory",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Brunei Darussalam",
              displayName: "Brunei Darussalam",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Cambodia",
              displayName: "Cambodia",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "China",
              displayName: "China",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Hong Kong",
              displayName: "Hong Kong",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "India",
              displayName: "India",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Indonesia",
              displayName: "Indonesia",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Japan",
              displayName: "Japan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Kazakhstan",
              displayName: "Kazakhstan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Korea, Democratic People`s Republic of",
              displayName: "Korea,Democratic People`s Republic of",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Korea, Republic of",
              displayName: "Korea, Republic of",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Kyrgyzstan",
              displayName: "Kyrgyzstan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Lao People`s Democratic Republic",
              displayName: "Lao People`s Democratic Republic",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Macao",
              displayName: "Macao",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Malaysia",
              displayName: "Malaysia",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Maldives",
              displayName: "Maldives",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Mongolia",
              displayName: "Mongolia",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Myanmar",
              displayName: "Myanmar",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Nepal",
              displayName: "Nepal",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Pakistan",
              displayName: "Pakistan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Philippines",
              displayName: "Philippines",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Singapore",
              displayName: "Singapore",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Sri Lanka",
              displayName: "Sri Lanka",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Taiwan",
              displayName: "Taiwan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Tajikistan",
              displayName: "Tajikistan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Thailand",
              displayName: "Thailand",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Timor-Leste",
              displayName: "Timor-Leste",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Turkmenistan",
              displayName: "Turkmenistan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Uzbekistan",
              displayName: "Uzbekistan",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Viet Nam",
              displayName: "Viet Nam",
              key: "Asia",
              __typename: "AttributeValue",
            },
            {
              code: "Albania",
              displayName: "Albania",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Andorra",
              displayName: "Andorra",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Armenia",
              displayName: "Armenia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Austria",
              displayName: "Austria",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Azerbaijan",
              displayName: "Azerbaijan",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Belarus",
              displayName: "Belarus",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Belgium",
              displayName: "Belgium",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Bosnia and Herzegovina",
              displayName: "Bosnia and Herzegovina",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Bulgaria",
              displayName: "Bulgaria",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Croatia",
              displayName: "Croatia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Cyprus",
              displayName: "Cyprus",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Czech Republic",
              displayName: "Czech Republic",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Denmark",
              displayName: "Denmark",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Estonia",
              displayName: "Estonia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Faroe Islands",
              displayName: "Faroe Islands",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Finland",
              displayName: "Finland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "France",
              displayName: "France",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Georgia",
              displayName: "Georgia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Germany",
              displayName: "Germany",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Gibraltar",
              displayName: "Gibraltar",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Greece",
              displayName: "Greece",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Greenland",
              displayName: "Greenland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Guernsey",
              displayName: "Guernsey",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Holy See (Vatican City State)",
              displayName: "Holy See (Vatican City State)",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Hungary",
              displayName: "Hungary",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Iceland",
              displayName: "Iceland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Ireland",
              displayName: "Ireland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Isle of Man",
              displayName: "Isle of Man",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Italy",
              displayName: "Italy",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Jersey",
              displayName: "Jersey",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Latvia",
              displayName: "Latvia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Liechtenstein",
              displayName: "Liechtenstein",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Lithuania",
              displayName: "Lithuania",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Luxembourg",
              displayName: "Luxembourg",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Malta",
              displayName: "Malta",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Moldova, Republic of",
              displayName: "Moldova, Republic of",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Monaco",
              displayName: "Monaco",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Montenegro",
              displayName: "Montenegro",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Netherlands",
              displayName: "Netherlands",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "North Macedonia, Republic of",
              displayName: "North Macedonia,Republic of",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Norway",
              displayName: "Norway",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Poland",
              displayName: "Poland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Portugal",
              displayName: "Portugal",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Romania",
              displayName: "Romania",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Russian Federation",
              displayName: "Russian Federation",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "San Marino",
              displayName: "San Marino",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Serbia",
              displayName: "Serbia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Slovakia",
              displayName: "Slovakia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Slovenia",
              displayName: "Slovenia",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Spain",
              displayName: "Spain",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Svalbard and Jan Mayen",
              displayName: "Svalbard and Jan Mayen",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Sweden",
              displayName: "Sweden",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Switzerland",
              displayName: "Switzerland",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Turkey",
              displayName: "Turkey",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Ukraine",
              displayName: "Ukraine",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "United Kingdom",
              displayName: "United Kingdom",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Åland Islands",
              displayName: "Åland Islands",
              key: "Europe",
              __typename: "AttributeValue",
            },
            {
              code: "Algeria",
              displayName: "Algeria",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Bahrain",
              displayName: "Bahrain",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Egypt",
              displayName: "Egypt",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Iran, Islamic Republic of",
              displayName: "Iran, Islamic Republic of",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Iraq",
              displayName: "Iraq",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Israel",
              displayName: "Israel",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Jordan",
              displayName: "Jordan",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Kuwait",
              displayName: "Kuwait",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Lebanon",
              displayName: "Lebanon",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Libya",
              displayName: "Libya",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Morocco",
              displayName: "Morocco",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Oman",
              displayName: "Oman",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Palestine, State of",
              displayName: "Palestine, State of",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Qatar",
              displayName: "Qatar",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Saudi Arabia",
              displayName: "Saudi Arabia",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Syrian Arab Republic",
              displayName: "Syrian Arab Republic",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Tunisia",
              displayName: "Tunisia",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "United Arab Emirates",
              displayName: "United Arab Emirates",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Yemen",
              displayName: "Yemen",
              key: "Middle East",
              __typename: "AttributeValue",
            },
            {
              code: "Bermuda",
              displayName: "Bermuda",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Canada",
              displayName: "Canada",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Nicaragua",
              displayName: "Nicaragua",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Pierre and Miquelon",
              displayName: "Saint Pierre and Miquelon",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Sint Maarten (Dutch part)",
              displayName: "Sint Maarten (Dutch part)",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "United States",
              displayName: "United States",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "United States Minor Outlying Islands",
              displayName: "United States Minor Outlying Islands",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Virgin Islands, British",
              displayName: "Virgin Islands, British",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "Virgin Islands, U.S.",
              displayName: "Virgin Islands, U.S.",
              key: "North America",
              __typename: "AttributeValue",
            },
            {
              code: "American Samoa",
              displayName: "American Samoa",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Australia",
              displayName: "Australia",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Christmas Island",
              displayName: "Christmas Island",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Cocos (Keeling) Islands",
              displayName: "Cocos (Keeling) Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Cook Islands",
              displayName: "Cook Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Fiji",
              displayName: "Fiji",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "French Polynesia",
              displayName: "French Polynesia",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Guam",
              displayName: "Guam",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Heard Island and McDonald Islands",
              displayName: "Heard Island and McDonald Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Kiribati",
              displayName: "Kiribati",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Marshall Islands",
              displayName: "Marshall Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Micronesia, Federated States of",
              displayName: "Micronesia, Federated States of",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Nauru",
              displayName: "Nauru",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "New Caledonia",
              displayName: "New Caledonia",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "New Zealand",
              displayName: "New Zealand",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Niue",
              displayName: "Niue",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Norfolk Island",
              displayName: "Norfolk Island",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Northern Mariana Islands",
              displayName: "Northern Mariana Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Palau",
              displayName: "Palau",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Papua New Guinea",
              displayName: "Papua New Guinea",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Pitcairn",
              displayName: "Pitcairn",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Samoa",
              displayName: "Samoa",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Solomon Islands",
              displayName: "Solomon Islands",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Tokelau",
              displayName: "Tokelau",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Tonga",
              displayName: "Tonga",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Tuvalu",
              displayName: "Tuvalu",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Vanuatu",
              displayName: "Vanuatu",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Wallis and Futuna",
              displayName: "Wallis and Futuna",
              key: "Oceania",
              __typename: "AttributeValue",
            },
            {
              code: "Anguilla",
              displayName: "Anguilla",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Antigua and Barbuda",
              displayName: "Antigua and Barbuda",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Argentina",
              displayName: "Argentina",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Aruba",
              displayName: "Aruba",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Bahamas",
              displayName: "Bahamas",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Barbados",
              displayName: "Barbados",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Belize",
              displayName: "Belize",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Bolivia,Plurinational State of",
              displayName: "Bolivia, Plurinational State of",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Bonaire,Sint Eustatius and Saba",
              displayName: "Bonaire, Sint Eustatius and Saba",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Brazil",
              displayName: "Brazil",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Cayman Islands",
              displayName: "Cayman Islands",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Chile",
              displayName: "Chile",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Colombia",
              displayName: "Colombia",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Costa Rica",
              displayName: "Costa Rica",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Cuba",
              displayName: "Cuba",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Curaçao",
              displayName: "Curaçao",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Dominica",
              displayName: "Dominica",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Dominican Republic",
              displayName: "Dominican Republic",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Ecuador",
              displayName: "Ecuador",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "El Salvador",
              displayName: "El Salvador",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Falkland Islands (Malvinas)",
              displayName: "Falkland Islands (Malvinas)",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "French Guiana",
              displayName: "French Guiana",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Grenada",
              displayName: "Grenada",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Guadeloupe",
              displayName: "Guadeloupe",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Guatemala",
              displayName: "Guatemala",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Guyana",
              displayName: "Guyana",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Haiti",
              displayName: "Haiti",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Honduras",
              displayName: "Honduras",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Jamaica",
              displayName: "Jamaica",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Martinique",
              displayName: "Martinique",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Mexico",
              displayName: "Mexico",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Montserrat",
              displayName: "Montserrat",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Panama",
              displayName: "Panama",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Paraguay",
              displayName: "Paraguay",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Peru",
              displayName: "Peru",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Puerto Rico",
              displayName: "Puerto Rico",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Barthélemy",
              displayName: "Saint Barthélemy",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Kitts and Nevis",
              displayName: "Saint Kitts and Nevis",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Lucia",
              displayName: "Saint Lucia",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Martin (French part)",
              displayName: "Saint Martin (French part)",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Saint Vincent and the Grenadines",
              displayName: "Saint Vincent and the Grenadines",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Suriname",
              displayName: "Suriname",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Trinidad and Tobago",
              displayName: "Trinidad and Tobago",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Turks and Caicos Islands",
              displayName: "Turks and Caicos Islands",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Uruguay",
              displayName: "Uruguay",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
            {
              code: "Venezuela,Bolivarian Republic of",
              displayName: "Venezuela, Bolivarian Republic of",
              key: "South/Latin America",
              __typename: "AttributeValue",
            },
          ],
          id: "9832378a-f880-4f0b-8f0e-6c77dd741d92",
          mandatory: false,
          __typename: "AssetAttribute",
        },
      ],
      displayName: "Carbon Credit",
      code: "CC",
      id: "cf0de93d-17ab-414e-b883-2f0a2fae4b62",
      name: "Carbon Credit",
      __typename: "Asset",
    },
  ],
};

export const demoAssets: RecOrderAttributesOptionsQuery = {
  assets: [
    {
      attributes: [
        {
          name: "vintage",
          values: [
            {
              code: "2023",
              displayName: "2023",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2022",
              displayName: "2022",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2021",
              displayName: "2021",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2020",
              displayName: "2020",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2019",
              displayName: "2019",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2018",
              displayName: "2018",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2017",
              displayName: "2017",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2016",
              displayName: "2016",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2015",
              displayName: "2015",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "3ed1043b-cadf-45c6-be5b-dcc57f00a52f",
          mandatory: true,
          __typename: "AssetAttribute",
        },
        {
          name: "eligibility",
          values: [
            {
              code: "ia",
              displayName: "Iowa",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "ia_aep",
              displayName: "Iowa AEP",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "ia_voluntary_green",
              displayName: "Iowa Voluntary Green",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "il_ares",
              displayName: " Illinois ARES",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "il_utility",
              displayName: "Illinois Utility",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "mb",
              displayName: "Manitoba",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "mn",
              displayName: "Minnesota",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "mn_ses_small_solar",
              displayName: "Minnesota SES Small Solar",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "mt",
              displayName: "Montana",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "nd",
              displayName: "North Dakota",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "oh",
              displayName: "Ohio",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "sd",
              displayName: "South Dakota",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "wi_bankable",
              displayName: "Wisconsin Bankable",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "wi_non_bankable",
              displayName: "Wisconsin Non Bankable",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "b9ae58d4-032e-46d7-a463-2d8e400182ef",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "location",
          values: [
            {
              code: "AL",
              displayName: "Alabama",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AK",
              displayName: "Alaska",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AS",
              displayName: "American Samoa",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AZ",
              displayName: "Arizona",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AR",
              displayName: "Arkansas",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AA",
              displayName: "Armed Forces Americas",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AE",
              displayName: "Armed Forces Europe",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AP",
              displayName: "Armed Forces Pacific",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "CA",
              displayName: "California",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "CO",
              displayName: "Colorado",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "CT",
              displayName: "Connecticut",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "DE",
              displayName: "Delaware",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "DC",
              displayName: "District of Columbia",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "FL",
              displayName: "Florida",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "GA",
              displayName: "Georgia",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "GU",
              displayName: "Guam",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "HI",
              displayName: "Hawaii",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "ID",
              displayName: "Idaho",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "IL",
              displayName: "Illinois",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "IN",
              displayName: "Indiana",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "IA",
              displayName: "Iowa",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "KS",
              displayName: "Kansas",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "KY",
              displayName: "Kentucky",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "LA",
              displayName: "Louisiana",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "ME",
              displayName: "Maine",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MH",
              displayName: "Marshall Islands",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MD",
              displayName: "Maryland",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MA",
              displayName: "Massachusetts",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MI",
              displayName: "Michigan",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MN",
              displayName: "Minnesota",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MS",
              displayName: "Mississippi",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MO",
              displayName: "Missouri",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "MT",
              displayName: "Montana",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NE",
              displayName: "Nebraska",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NV",
              displayName: "Nevada",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NH",
              displayName: "New Hampshire",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NJ",
              displayName: "New Jersey",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NM",
              displayName: "New Mexico",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NY",
              displayName: "New York",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NC",
              displayName: "North Carolina",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "ND",
              displayName: "North Dakota",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "NP",
              displayName: "Northern Mariana Islands",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "OH",
              displayName: "Ohio",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "OK",
              displayName: "Oklahoma",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "OR",
              displayName: "Oregon",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "PA",
              displayName: "Pennsylvania",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "PR",
              displayName: "Puerto Rico",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "RI",
              displayName: "Rhode Island",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "SC",
              displayName: "South Carolina",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "SD",
              displayName: "South Dakota",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "TN",
              displayName: "Tennessee",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "TX",
              displayName: "Texas",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "VI",
              displayName: "US Virgin Islands",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "UT",
              displayName: "Utah",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "VT",
              displayName: "Vermont",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "VA",
              displayName: "Virginia",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "WA",
              displayName: "Washington",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "WV",
              displayName: "West Virginia",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "WI",
              displayName: "Wisconsin",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "WY",
              displayName: "Wyoming",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "UM",
              displayName: "United States Minor Outlying Islands",
              key: "USA",
              __typename: "AttributeValue",
            },
            {
              code: "AB",
              displayName: "Alberta",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "BC",
              displayName: "British Columbia",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "MB",
              displayName: "Manitoba",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "NB",
              displayName: "New Brunswick",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "NF",
              displayName: "Newfoundland and Labrador",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "NT",
              displayName: "Northwest Territories",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "NS",
              displayName: "Nova Scotia",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "NU",
              displayName: "Nunavut",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "ON",
              displayName: "Ontario",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "PE",
              displayName: "Prince Edward Island",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "QC",
              displayName: "Quebec",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "SK",
              displayName: "Saskatchewan",
              key: "Canada",
              __typename: "AttributeValue",
            },
            {
              code: "YT",
              displayName: "Yukon",
              key: "Canada",
              __typename: "AttributeValue",
            },
          ],
          id: "3d361967-f043-4b0b-920c-aabb37e742ee",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "fuelSource",
          values: [
            {
              code: "ARS",
              displayName: "Air Source",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "ALT",
              displayName: "Alternate Use",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BIG",
              displayName: "Biogas",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BIM",
              displayName: "Biomass",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BM2",
              displayName: "Biomass (WI Eligible)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BAF",
              displayName: "Biomass - Alt fuels from plant oils or animal fats",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BLQ",
              displayName: "Biomass - Black Liquor",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "RWB",
              displayName: "Biomass - Recovered Woody Biomass",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BSW",
              displayName: "Biomass - sustainably managed woody biomass",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "AB1",
              displayName: "Biomass - Agricultural Crop (closed loop)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "AB2",
              displayName: "Biomass - Agricultural Crop (open loop)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BAW",
              displayName: "Biomass - Agricultural Waste",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BA3",
              displayName: "Biomass - Animal Waste - Other",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BA2",
              displayName: "Biomass - Animal Waste - Poultry",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BA1",
              displayName: "Biomass - Animal Waste - Swine",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BBW",
              displayName: "Biomass - Biological Waste",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BHV",
              displayName: "Biomass - Herbaceous Vegetative Matter or Residue",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BID",
              displayName: "Biomass - Incineration of Construction Debris",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BGR",
              displayName: "Biomass - Incineration of Garbage",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BBL",
              displayName: "Biomass - Liquids",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BNV",
              displayName: "Biomass - Non vegetation waste",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "PA1",
              displayName: "Biomass - Paper-derived",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "RDF",
              displayName: "Biomass - Refuse-Derived Fuel",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SLW",
              displayName: "Biomass - Sludge Waste",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BTW",
              displayName: "Biomass - Treated Organic Waste Biomass",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BUW",
              displayName: "Biomass - Untreated Organic Waste Biomass",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WDR",
              displayName: "Biomass - Wood - Railroad Ties",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WDU",
              displayName: "Biomass - Wood - Utility Poles",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WDL",
              displayName: "Biomass - Wood - Wood Waste Liquids",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WDS",
              displayName: "Biomass - Wood - Wood/Wood Waste Solids",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "CHE",
              displayName: "Compost Heat Exchange System",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "CON",
              displayName: "Conversion of Fuel from Natural Gas Pipeline",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "FC1",
              displayName: "Fuel Cells",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BFG",
              displayName: "Gas - Blast-Furnace Gas",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "GE1",
              displayName: "Geothermal Energy",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "GWS",
              displayName: "Ground And Water Source Head Pump",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "HY2",
              displayName: "Hydro (WI Eligible)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "H2O",
              displayName: "Hydroelectric Water",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "HYD",
              displayName: "Hydrogen",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "MS1",
              displayName: "Municipal solid Waste",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "RNG",
              displayName: "Renewable Natural Gas",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SO1",
              displayName: "Solar",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "SOT",
              displayName: "Solar Thermal",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "OC1",
              displayName: "Tidal or Wave",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "TDF",
              displayName: "Tire Derived Fuel",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WHR",
              displayName: "Waste Heat Recovery",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WND",
              displayName: "Wind",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WD2",
              displayName: "Wind (WI Eligible)",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "WBS",
              displayName: "Woody Biomass System",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "59899830-e608-4c4f-bdd1-888dd8075dd7",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "certification",
          values: [
            {
              code: "CRS",
              displayName: "CRS Listed",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "ecologo",
              displayName: "Ecologo Certified",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "68f66867-4281-4645-9cc7-ea6aaa6b8f11",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "vintageHalf",
          values: [
            {
              code: "FRONT_HALF",
              displayName: "Front Half",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "BACK_HALF",
              displayName: "Back Half",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "82418df3-3803-4b68-90c2-dd283366f430",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "project",
          values: [
            {
              code: "09r5fyuxdymbdh9x3cprjzmnw03p2pwo",
              displayName: "yawning seashore",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "139lmq4ecgv1d6ijcyg88djcdtvyahre",
              displayName: "honored worker",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "1gm6hyz6hfnif3vbe2amgguayzo1jz5o",
              displayName: "shady deposit",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "1y0qjyct5gdq6rkieh041orqnfjagcud",
              displayName: "irritating swordfight",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2ln3zm72si4vtvwu2m98v4nrsot30z1v",
              displayName: "well-worn answer",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "2y8cp3f6ifscmw2uaym5m4elti93fqnd",
              displayName: "complex vaccine",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "44z4m33x0kk3wtpa0uf9pzt1lzxbylxo",
              displayName: "blue ketchup",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "4pmf5js3pwvlau9xbxctf3unfcvfq16d",
              displayName: "frail font",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "80vp1seibbbx11vekvcsx9q6a4s13ars",
              displayName: "joyful magic",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "ava5bgkdt5uwt236yn6ybt7yu10l8qfo",
              displayName: "quick junk",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "b5kkpxga64lx1qkxnb88j63o4xp0uwfj",
              displayName: "plush elicit",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "bnyucjh614c4azbhgadg38d7p7efa29t",
              displayName: "messy celery",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "eqkjq3y8qlrosko5eiy37cocvxrhd3lm",
              displayName: "devoted queen",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "f5pv4ef05e8so5jpg0krz88vm6zy9359",
              displayName: "similar admin",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "mwe7b0082cotgnzcvxe85fhawoqan6np",
              displayName: "crowded centimeter",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "o725hofzlxmd9daeo6i6chdqi3v434md",
              displayName: "curvy movie",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "s4w7fktxzh0lt9ed6j0k1493y5pdapwb",
              displayName: "unaware fortune",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "uaomx181f12x8c6kdgjgx774q3l8ryuh",
              displayName: "identical tempo",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "uxci18pb0402rx4340ag7nzqglmmu2ol",
              displayName: "squeaky finisher",
              key: null,
              __typename: "AttributeValue",
            },
            {
              code: "w9sbft70wms2w3fgbkr85ue7rhdsi6s2",
              displayName: "defensive seeder",
              key: null,
              __typename: "AttributeValue",
            },
          ],
          id: "33cadbdc-dffa-44a4-b8c7-4efe13807a7d",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "location",
          values: [
            {
              code: "AL",
              displayName: "Alabama",
              __typename: "AttributeValue",
            },
            {
              code: "AK",
              displayName: "Alaska",
              __typename: "AttributeValue",
            },
            {
              code: "AS",
              displayName: "American Samoa",
              __typename: "AttributeValue",
            },
            {
              code: "AZ",
              displayName: "Arizona",
              __typename: "AttributeValue",
            },
            {
              code: "AR",
              displayName: "Arkansas",
              __typename: "AttributeValue",
            },
            {
              code: "AA",
              displayName: "Armed Forces Americas",
              __typename: "AttributeValue",
            },
            {
              code: "AE",
              displayName: "Armed Forces Europe",
              __typename: "AttributeValue",
            },
            {
              code: "AP",
              displayName: "Armed Forces Pacific",
              __typename: "AttributeValue",
            },
            {
              code: "CA",
              displayName: "California",
              __typename: "AttributeValue",
            },
            {
              code: "CO",
              displayName: "Colorado",
              __typename: "AttributeValue",
            },
            {
              code: "CT",
              displayName: "Connecticut",
              __typename: "AttributeValue",
            },
            {
              code: "DE",
              displayName: "Delaware",
              __typename: "AttributeValue",
            },
            {
              code: "DC",
              displayName: "District of Columbia",
              __typename: "AttributeValue",
            },
            {
              code: "FL",
              displayName: "Florida",
              __typename: "AttributeValue",
            },
            {
              code: "GA",
              displayName: "Georgia",
              __typename: "AttributeValue",
            },
            {
              code: "GU",
              displayName: "Guam",
              __typename: "AttributeValue",
            },
            {
              code: "HI",
              displayName: "Hawaii",
              __typename: "AttributeValue",
            },
            {
              code: "ID",
              displayName: "Idaho",
              __typename: "AttributeValue",
            },
            {
              code: "IL",
              displayName: "Illinois",
              __typename: "AttributeValue",
            },
            {
              code: "IN",
              displayName: "Indiana",
              __typename: "AttributeValue",
            },
            {
              code: "IA",
              displayName: "Iowa",
              __typename: "AttributeValue",
            },
            {
              code: "KS",
              displayName: "Kansas",
              __typename: "AttributeValue",
            },
            {
              code: "KY",
              displayName: "Kentucky",
              __typename: "AttributeValue",
            },
            {
              code: "LA",
              displayName: "Louisiana",
              __typename: "AttributeValue",
            },
            {
              code: "ME",
              displayName: "Maine",
              __typename: "AttributeValue",
            },
            {
              code: "MH",
              displayName: "Marshall Islands",
              __typename: "AttributeValue",
            },
            {
              code: "MD",
              displayName: "Maryland",
              __typename: "AttributeValue",
            },
            {
              code: "MA",
              displayName: "Massachusetts",
              __typename: "AttributeValue",
            },
            {
              code: "MI",
              displayName: "Michigan",
              __typename: "AttributeValue",
            },
            {
              code: "MN",
              displayName: "Minnesota",
              __typename: "AttributeValue",
            },
            {
              code: "MS",
              displayName: "Mississippi",
              __typename: "AttributeValue",
            },
            {
              code: "MO",
              displayName: "Missouri",
              __typename: "AttributeValue",
            },
            {
              code: "MT",
              displayName: "Montana",
              __typename: "AttributeValue",
            },
            {
              code: "NE",
              displayName: "Nebraska",
              __typename: "AttributeValue",
            },
            {
              code: "NV",
              displayName: "Nevada",
              __typename: "AttributeValue",
            },
            {
              code: "NH",
              displayName: "New Hampshire",
              __typename: "AttributeValue",
            },
            {
              code: "NJ",
              displayName: "New Jersey",
              __typename: "AttributeValue",
            },
            {
              code: "NM",
              displayName: "New Mexico",
              __typename: "AttributeValue",
            },
            {
              code: "NY",
              displayName: "New York",
              __typename: "AttributeValue",
            },
            {
              code: "NC",
              displayName: "North Carolina",
              __typename: "AttributeValue",
            },
            {
              code: "ND",
              displayName: "North Dakota",
              __typename: "AttributeValue",
            },
            {
              code: "NP",
              displayName: "Northern Mariana Islands",
              __typename: "AttributeValue",
            },
            {
              code: "OH",
              displayName: "Ohio",
              __typename: "AttributeValue",
            },
            {
              code: "OK",
              displayName: "Oklahoma",
              __typename: "AttributeValue",
            },
            {
              code: "OR",
              displayName: "Oregon",
              __typename: "AttributeValue",
            },
            {
              code: "PA",
              displayName: "Pennsylvania",
              __typename: "AttributeValue",
            },
            {
              code: "PR",
              displayName: "Puerto Rico",
              __typename: "AttributeValue",
            },
            {
              code: "RI",
              displayName: "Rhode Island",
              __typename: "AttributeValue",
            },
            {
              code: "SC",
              displayName: "South Carolina",
              __typename: "AttributeValue",
            },
            {
              code: "SD",
              displayName: "South Dakota",
              __typename: "AttributeValue",
            },
            {
              code: "TN",
              displayName: "Tennessee",
              __typename: "AttributeValue",
            },
            {
              code: "TX",
              displayName: "Texas",
              __typename: "AttributeValue",
            },
            {
              code: "VI",
              displayName: "US Virgin Islands",
              __typename: "AttributeValue",
            },
            {
              code: "UT",
              displayName: "Utah",
              __typename: "AttributeValue",
            },
            {
              code: "VT",
              displayName: "Vermont",
              __typename: "AttributeValue",
            },
            {
              code: "VA",
              displayName: "Virginia",
              __typename: "AttributeValue",
            },
            {
              code: "WA",
              displayName: "Washington",
              __typename: "AttributeValue",
            },
            {
              code: "WV",
              displayName: "West Virginia",
              __typename: "AttributeValue",
            },
            {
              code: "WI",
              displayName: "Wisconsin",
              __typename: "AttributeValue",
            },
            {
              code: "WY",
              displayName: "Wyoming",
              __typename: "AttributeValue",
            },
            {
              code: "UM",
              displayName: "United States Minor Outlying Islands",
              __typename: "AttributeValue",
            },
            {
              code: "AB",
              displayName: "Alberta",
              __typename: "AttributeValue",
            },
            {
              code: "BC",
              displayName: "British Columbia",
              __typename: "AttributeValue",
            },
            {
              code: "MB",
              displayName: "Manitoba",
              __typename: "AttributeValue",
            },
            {
              code: "NB",
              displayName: "New Brunswick",
              __typename: "AttributeValue",
            },
            {
              code: "NF",
              displayName: "Newfoundland and Labrador",
              __typename: "AttributeValue",
            },
            {
              code: "NT",
              displayName: "Northwest Territories",
              __typename: "AttributeValue",
            },
            {
              code: "NS",
              displayName: "Nova Scotia",
              __typename: "AttributeValue",
            },
            {
              code: "NU",
              displayName: "Nunavut",
              __typename: "AttributeValue",
            },
            {
              code: "ON",
              displayName: "Ontario",
              __typename: "AttributeValue",
            },
            {
              code: "PE",
              displayName: "Prince Edward Island",
              __typename: "AttributeValue",
            },
            {
              code: "QC",
              displayName: "Quebec",
              __typename: "AttributeValue",
            },
            {
              code: "SK",
              displayName: "Saskatchewan",
              __typename: "AttributeValue",
            },
            {
              code: "YT",
              displayName: "Yukon",
              __typename: "AttributeValue",
            },
          ],
          id: "2dfb8263-eb14-43e3-9e48-903e06dae326",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "eligibility",
          values: [
            {
              code: "ecologo",
              displayName: "Ecologo Certified",
              __typename: "AttributeValue",
            },
            {
              code: "ia",
              displayName: "Iowa",
              __typename: "AttributeValue",
            },
            {
              code: "ia_aep",
              displayName: "Iowa AEP",
              __typename: "AttributeValue",
            },
            {
              code: "ia_voluntary_green",
              displayName: "Iowa Voluntary Green",
              __typename: "AttributeValue",
            },
            {
              code: "il_ares",
              displayName: " Illinois ARES",
              __typename: "AttributeValue",
            },
            {
              code: "il_utility",
              displayName: "Illinois Utility",
              __typename: "AttributeValue",
            },
            {
              code: "mb",
              displayName: "Manitoba",
              __typename: "AttributeValue",
            },
            {
              code: "mn",
              displayName: "Minnesota",
              __typename: "AttributeValue",
            },
            {
              code: "mn_ses_small_solar",
              displayName: "Minnesota SES Small Solar",
              __typename: "AttributeValue",
            },
            {
              code: "mt",
              displayName: "Montana",
              __typename: "AttributeValue",
            },
            {
              code: "nd",
              displayName: "North Dakota",
              __typename: "AttributeValue",
            },
            {
              code: "oh",
              displayName: "Ohio",
              __typename: "AttributeValue",
            },
            {
              code: "sd",
              displayName: "South Dakota",
              __typename: "AttributeValue",
            },
            {
              code: "wi_bankable",
              displayName: "Wisconsin Bankable",
              __typename: "AttributeValue",
            },
            {
              code: "wi_non_bankable",
              displayName: "Wisconsin Non Bankable",
              __typename: "AttributeValue",
            },
          ],
          id: "6ca39e14-fd25-46a3-ae37-0c08fa6de25b",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "vintage",
          values: [
            {
              code: "2022",
              displayName: "2022",
              __typename: "AttributeValue",
            },
            {
              code: "2021",
              displayName: "2021",
              __typename: "AttributeValue",
            },
            {
              code: "2020",
              displayName: "2020",
              __typename: "AttributeValue",
            },
            {
              code: "2019",
              displayName: "2019",
              __typename: "AttributeValue",
            },
            {
              code: "2018",
              displayName: "2018",
              __typename: "AttributeValue",
            },
            {
              code: "2017",
              displayName: "2017",
              __typename: "AttributeValue",
            },
            {
              code: "2016",
              displayName: "2016",
              __typename: "AttributeValue",
            },
            {
              code: "2015",
              displayName: "2015",
              __typename: "AttributeValue",
            },
          ],
          id: "d590f97d-5317-4558-b148-7a1c327c660c",
          mandatory: true,
          __typename: "AssetAttribute",
        },
        {
          name: "fuelSource",
          values: [
            {
              code: "ARS",
              displayName: "Air Source",
              __typename: "AttributeValue",
            },
            {
              code: "ALT",
              displayName: "Alternate Use",
              __typename: "AttributeValue",
            },
            {
              code: "BIG",
              displayName: "Biogas",
              __typename: "AttributeValue",
            },
            {
              code: "BIM",
              displayName: "Biomass",
              __typename: "AttributeValue",
            },
            {
              code: "BM2",
              displayName: "Biomass (WI Eligible)",
              __typename: "AttributeValue",
            },
            {
              code: "BAF",
              displayName: "Biomass - Alt fuels from plant oils or animal fats",
              __typename: "AttributeValue",
            },
            {
              code: "BLQ",
              displayName: "Biomass - Black Liquor",
              __typename: "AttributeValue",
            },
            {
              code: "RWB",
              displayName: "Biomass - Recovered Woody Biomass",
              __typename: "AttributeValue",
            },
            {
              code: "BSW",
              displayName: "Biomass - sustainably managed woody biomass",
              __typename: "AttributeValue",
            },
            {
              code: "AB1",
              displayName: "Biomass - Agricultural Crop (closed loop)",
              __typename: "AttributeValue",
            },
            {
              code: "AB2",
              displayName: "Biomass - Agricultural Crop (open loop)",
              __typename: "AttributeValue",
            },
            {
              code: "BAW",
              displayName: "Biomass - Agricultural Waste",
              __typename: "AttributeValue",
            },
            {
              code: "BA3",
              displayName: "Biomass - Animal Waste - Other",
              __typename: "AttributeValue",
            },
            {
              code: "BA2",
              displayName: "Biomass - Animal Waste - Poultry",
              __typename: "AttributeValue",
            },
            {
              code: "BA1",
              displayName: "Biomass - Animal Waste - Swine",
              __typename: "AttributeValue",
            },
            {
              code: "BBW",
              displayName: "Biomass - Biological Waste",
              __typename: "AttributeValue",
            },
            {
              code: "BHV",
              displayName: "Biomass - Herbaceous Vegetative Matter or Residue",
              __typename: "AttributeValue",
            },
            {
              code: "BID",
              displayName: "Biomass - Incineration of Construction Debris",
              __typename: "AttributeValue",
            },
            {
              code: "BGR",
              displayName: "Biomass - Incineration of Garbage",
              __typename: "AttributeValue",
            },
            {
              code: "BBL",
              displayName: "Biomass - Liquids",
              __typename: "AttributeValue",
            },
            {
              code: "BNV",
              displayName: "Biomass - Non vegetation waste",
              __typename: "AttributeValue",
            },
            {
              code: "PA1",
              displayName: "Biomass - Paper-derived",
              __typename: "AttributeValue",
            },
            {
              code: "RDF",
              displayName: "Biomass - Refuse-Derived Fuel",
              __typename: "AttributeValue",
            },
            {
              code: "SLW",
              displayName: "Biomass - Sludge Waste",
              __typename: "AttributeValue",
            },
            {
              code: "BTW",
              displayName: "Biomass - Treated Organic Waste Biomass",
              __typename: "AttributeValue",
            },
            {
              code: "BUW",
              displayName: "Biomass - Untreated Organic Waste Biomass",
              __typename: "AttributeValue",
            },
            {
              code: "WDR",
              displayName: "Biomass - Wood - Railroad Ties",
              __typename: "AttributeValue",
            },
            {
              code: "WDU",
              displayName: "Biomass - Wood - Utility Poles",
              __typename: "AttributeValue",
            },
            {
              code: "WDL",
              displayName: "Biomass - Wood - Wood Waste Liquids",
              __typename: "AttributeValue",
            },
            {
              code: "WDS",
              displayName: "Biomass - Wood - Wood/Wood Waste Solids",
              __typename: "AttributeValue",
            },
            {
              code: "CHE",
              displayName: "Compost Heat Exchange System",
              __typename: "AttributeValue",
            },
            {
              code: "CON",
              displayName: "Conversion of Fuel from Natural Gas Pipeline",
              __typename: "AttributeValue",
            },
            {
              code: "FC1",
              displayName: "Fuel Cells",
              __typename: "AttributeValue",
            },
            {
              code: "BFG",
              displayName: "Gas - Blast-Furnace Gas",
              __typename: "AttributeValue",
            },
            {
              code: "GE1",
              displayName: "Geothermal Energy",
              __typename: "AttributeValue",
            },
            {
              code: "GWS",
              displayName: "Ground And Water Source Head Pump",
              __typename: "AttributeValue",
            },
            {
              code: "HY2",
              displayName: "Hydro (WI Eligible)",
              __typename: "AttributeValue",
            },
            {
              code: "H2O",
              displayName: "Hydroelectric Water",
              __typename: "AttributeValue",
            },
            {
              code: "HYD",
              displayName: "Hydrogen",
              __typename: "AttributeValue",
            },
            {
              code: "MS1",
              displayName: "Municipal solid Waste",
              __typename: "AttributeValue",
            },
            {
              code: "RNG",
              displayName: "Renewable Natural Gas",
              __typename: "AttributeValue",
            },
            {
              code: "SO1",
              displayName: "Solar",
              __typename: "AttributeValue",
            },
            {
              code: "SOT",
              displayName: "Solar Thermal",
              __typename: "AttributeValue",
            },
            {
              code: "OC1",
              displayName: "Tidal or Wave",
              __typename: "AttributeValue",
            },
            {
              code: "TDF",
              displayName: "Tire Derived Fuel",
              __typename: "AttributeValue",
            },
            {
              code: "WHR",
              displayName: "Waste Heat Recovery",
              __typename: "AttributeValue",
            },
            {
              code: "WND",
              displayName: "Wind",
              __typename: "AttributeValue",
            },
            {
              code: "WD2",
              displayName: "Wind (WI Eligible)",
              __typename: "AttributeValue",
            },
            {
              code: "WBS",
              displayName: "Woody Biomass System",
              __typename: "AttributeValue",
            },
          ],
          id: "fcd96876-29cf-41a4-b968-f29530b68fec",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "certification",
          values: [
            {
              code: "CRS",
              displayName: "CRS Listed",
              __typename: "AttributeValue",
            },
          ],
          id: "cc166dce-a1d9-4776-9fd5-30b00ade7164",
          mandatory: false,
          __typename: "AssetAttribute",
        },
        {
          name: "project",
          values: [
            {
              code: "Chipmunk-Solar",
              displayName: "Chipmunk Solar",
            },
            {
              code: "Holiday-Creek-Solar",
              displayName: "Holiday Creek Solar",
            },
            {
              code: "Viking-Wind",
              displayName: "Viking Wind",
            },
            {
              code: "Nobles-Wind",
              displayName: "Nobles Wind",
            },
          ],
          id: "57721abc-b0ea-43bb-b8a0-7cb8ac3616b2",
          mandatory: false,
        },
      ],
      displayName: "REC",
      code: "REC",
      id: "910b3752-f4b1-4713-b940-cf51dce65a9d",
      name: "REC",
      __typename: "Asset",
    },
  ],
};
