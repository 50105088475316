import { ElectricityUom, Tenant } from "@/app/types/generated/graphql";

export const demoTenants: Array<Tenant> = [
  {
    id: "08cd2505-18d4-488b-a960-2195463332e8",
    name: "US",
    displayName: "US",
    electricityUom: ElectricityUom.Kwh,
    startDateTime: null,
    endDateTime: null,
    localisation: {
      id: "08cd2505-18d4-488b-a960-2195463332e4",
      country: "USA",
      countryCode: "US",
      currencyCode: "USD",
      currencySymbol: "$",
      currencyConversionRate: 4,
      __typename: "Localisation",
    },
    paymentProviders: [
      {
        __typename: "PaymentProviderV2",
        id: "4dcaaa65-c49e-4543-9fd0-0c5c36029983",
        name: "DWOLLA",
        displayName: "Dwolla",
        description: "Dwolla",
        paymentMethods: [],
      },
    ],
    __typename: "Tenant",
  },
];
