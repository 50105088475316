import { MessageStatus } from "@/app/types/generated/graphql";

export const demoMessages = [
  {
    id: "3",
    subject: "Purchase",
    body: "One of your orders has been completed",
    status: MessageStatus.Read,
    dateReceived: "2022-03-10T00:56:42.788203Z",
    dateRead: null,
  },
  {
    id: "2",
    subject: "Purchase",
    body: "One of your orders has been completed",
    status: MessageStatus.Unread,
    dateReceived: "2022-02-10T00:56:42.788203Z",
    dateRead: null,
  },
  {
    id: "1",
    subject: "Welcome",
    body: "Your account has been aproved by Tracex, you can now start trading",
    status: MessageStatus.Unread,
    dateReceived: "2022-01-10T00:56:42.788203Z",
    dateRead: null,
  },
];
