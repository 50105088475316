import { CcHoldingsQuery } from "@/app/types/generated/graphql";
export const demoHoldings: CcHoldingsQuery["ccHoldings"] = [
  {
    id: "79ace926-9f90-4037-be80-baca08455bf8",
    availableBalance: 600,
    currentBalance: 600,
    attributes: {
      __typename: "CCAssetAttributes",
      clientCode: "0001",
      coBenefits: [],
      country: "IN",
      projectId: "P-12354",
      projectLink: "https://powerledger.io",
      projectName: "P Name",
      projectTypes: ["Solar", "Wind"],
      registryAccountName: "Powerleder",
      registryAccountNumber: "R-123",
      registryName: "Gold",
      sdgGoals: ["SDG 2 - Zero Hunger", "SDG 1- No Poverty"],
      sectoralScopes: ["Energy Industries - non renewable sources", "Energy industries - renewable sources"],
      serialNumber: "P-123123123",
      vintage: 2022,
    },
  },
  {
    id: "79ace991-9f90-4037-be80-baca08455bf8",
    availableBalance: 12,
    currentBalance: 400,
    attributes: {
      __typename: "CCAssetAttributes",
      clientCode: "0002",
      coBenefits: ["CORSIA Labelled", "GCC"],
      country: "IN",
      projectId: "P-5555",
      projectLink: "https://powerledger.io",
      projectName: "P Name 2",
      projectTypes: ["Biogas", "Hydro", "Solar", "Wind"],
      registryAccountName: "Powerleder",
      registryAccountNumber: "R-1123",
      registryName: "Verra",
      sdgGoals: ["SDG 2 - Zero Hunger", "SDG 1- No Poverty"],
      sectoralScopes: ["Energy Industries - non renewable sources", "Energy industries - renewable sources"],
      serialNumber: "P-11111",
      vintage: 2020,
    },
  },
];
